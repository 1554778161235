/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 ../../../public/itklogo.glb
*/

import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/itklogo.glb')
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Curve.geometry}
        material={materials['mat.001']}
        position={[-2.012, 0, 1.999]}
        scale={5.149}
      />
      <mesh
        geometry={nodes.Curve001.geometry}
        material={materials['mat.001']}
        position={[-2.012, 0, 1.999]}
        scale={5.149}
      />
      <mesh
        geometry={nodes.Curve002.geometry}
        material={materials['mat.001']}
        position={[-2.012, 0, 1.999]}
        scale={5.149}
      />
    </group>
  )
}

//useGLTF.preload('/itklogo.glb')

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.12 /workspace/app/public/animator.glb
*/

import { useGLTF } from '@react-three/drei'

export function Model(props) {
  const { nodes, materials } = useGLTF('/animator.glb')
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Curve.geometry}
        material={materials.Material}
        scale={128}
      />
    </group>
  )
}

//useGLTF.preload('/animator.glb')
